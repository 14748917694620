import { CrmBaseTransformer } from 'common-module/endpoint';
import { CrmUser } from 'common-module/user';
import { parseISO } from 'date-fns';
import { assign, isNil, omitBy } from 'lodash-es';

import { User } from './user';

export class UserResponseTransformer implements CrmBaseTransformer<User> {
  transform(data: CrmUser & { id?: string }): User {
    const user = assign(new User(), omitBy(data ?? {}, isNil));
    user._id = data._id ?? data.id;
    user.created = parseISO(data.createdAt!);
    user.updated = parseISO(data.updatedAt!);
    return user;
  }
}
