import { CrmDictionary } from 'common-module/core/types';
import { CrmEndpointListResponse } from 'common-module/endpoint';
import { CrmUserEndpoint } from 'common-module/user';
import { map, Observable } from 'rxjs';

import { User } from './user';

export class UserEndpoint extends CrmUserEndpoint<User> {
  override list(
    options?: CrmDictionary,
  ): Observable<CrmEndpointListResponse<User>> {
    return this.client
      .get<
        CrmEndpointListResponse<User>
      >(`${this.endpoint({})}/search`, this.getReqOptions(options))
      .pipe(
        map((response) => {
          return {
            ...response,
            data: response.data.map(this.responseTransformer.transform),
          };
        }),
      );
  }
}
