import { crmGetUserName, CrmUser, CrmUserRoles } from 'common-module/user';

export type UserRoles = CrmUserRoles | 'advisor';

export class User
  implements Omit<CrmUser, 'roles' | 'createdAt' | 'updatedAt'>
{
  _id!: string;
  street?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  city?: string;
  postalCode?: string;
  title?: string;
  meta?: {
    idcard: string;
    ico: string;
  };
  roles!: Record<UserRoles, boolean>;
  created!: Date;
  updated!: Date;
  locale?: string;

  getFullName() {
    return crmGetUserName(this);
  }

  withVisibleRoles<Result>(
    action: (role: UserRoles, active: boolean, result: Result) => void,
    init: Result,
  ): Result {
    return Object.entries(this.roles).reduce((result, [role, active]) => {
      if (visibleRoles.includes(role as UserRoles)) {
        action(role as UserRoles, active, result);
      }
      return result;
    }, init);
  }
}

export const visibleRoles: UserRoles[] = ['admin', 'advisor', 'active'];
